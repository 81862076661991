<template>
  <div class="base-forecast mt-2">
    <el-row :gutter="12" class="margin-bottom-12">
      <el-col :lg="4" :md="4" :sm="4" :xs="4">
        <KpiCardV2
          :title="
            checkIsValueAvailableOrNot(
              'object',
              chartmetricSpotifyListenersGrowth,
              '36Months',
              '%'
            )
          "
          :text="'36M Growth'"
          :padding="true"
          :pdf="pdf"
        />
      </el-col>
      <el-col :lg="4" :md="4" :sm="4" :xs="4">
        <KpiCardV2
          :title="
            calculate36MonthAvg(chartmetricSpotifyListenersGrowth['36Months'])
          "
          :text="'36M Avg. YOY Growth'"
          :padding="true"
          :pdf="pdf"
        />
      </el-col>
      <el-col :lg="16" :md="16" :sm="16" :xs="16">
        <div class="shadow-card card kpi-card-body">
          <el-row :gutter="12" class="ps-4 pe-4">
            <el-col
              :lg="8"
              :md="8"
              :sm="8"
              :xs="8"
              class="border-right theme-color"
            >
              <p
                class="kpi-card-title truncate pt-2 mb-0 fw-boldest"
                data-tag="p"
                title=""
                data-tooltip="true"
                :data-text="assumedGrowthRate + '%'"
                :style="[pdf ? 'font-size: 29px' : 'font-size: 35px']"
              >
                {{ assumedGrowthRate + "%" }}
              </p>
              <p
                :class="['truncate mb-0', pdf ? 'pdf-text' : 'kpi-card-text ']"
                data-tag="p"
                title=""
                data-tooltip="true"
                :data-text="'Assumed YOY Growth Rate'"
              >
                Assumed YOY Growth Rate
              </p>
            </el-col>
            <el-col
              :lg="4"
              :md="4"
              :sm="4"
              :xs="4"
              v-for="(revenue, i) in baseForecastRevenuesSum"
              :key="i"
              class="pt-2"
            >
              <KpiCardV2
                :title="checkIfValueGreaterThanZeroThenHumanize(revenue)"
                :text="'Revenues Fy' + (i + 1)"
                :card="false"
                :pdf="pdf"
              />
            </el-col>
          </el-row>
        </div>
      </el-col>
    </el-row>
    <div
      class="shadow-card card mt-2 mb-5 mb-xl-10"
      v-if="
        currentStatus === Status.IDLE ||
        currentStatus === Status.RUNNING ||
        loading
      "
    >
      <EmptyCardLoading
        :loading="loading"
        :type="'skeleton'"
        v-if="loading"
        :card-height="'500px'"
        :rows="11"
      />
    </div>
    <div
      class="base-forecast-chart"
      v-else-if="
        currentStatus === Status.SUCCESS &&
        spotifyListeners.length &&
        spotifyListeners.length > 0 &&
        !loading
      "
    >
      <EstimatedRevenuesV2
        @revenueUpdated="revenueUpdated"
        :spotify-listeners="spotifyListeners"
        :spotify-listeners-months="spotifyListenersMonth"
        :months="months"
        :show-html="false"
      />
      <ForecastV2
        :showButtons="false"
        title="Base Forecast"
        description=""
        :revenueLastMonths="revenueLastMonths"
        :current-spotify-followers="currentSpotifyMonthlyListeners"
        :spotify-followers-a-year-ago="yearBackSpotifyMonthlyListeners"
        :growthRate="growthRate"
        @yearsChanged="yearsChanged"
        :selected-years="years"
        :monthly-mean="monthlyMean"
        :show-exit-evaluation-revenue-card="false"
        :chart-type="'bar'"
        :stroke-width="[2, 2, 2]"
        :colors="['#009EF7', '#50CD89', '#F1416C']"
        :plot-options="{
          bar: {
            horizontal: false,
            startingShape: 'flat',
            // endingShape: 'rounded',
            columnWidth: '80%',
          },
        }"
        :chart-class="true"
        :stacked="false"
        :categories-type-yearly="false"
        :modal-title="'Configure Base Forecast'"
        @assumedGrowth="assumedGrowth"
      />
    </div>
  </div>
</template>
<script>
import KpiCardV2 from "@/common/components/KpiCardV2.vue";
import ForecastV2 from "@/common/components/ForecastV2.vue";
import EstimatedRevenuesV2 from "@/artists/components/EstimatedRevenuesV2.vue";
import EmptyCardLoading from "@/common/components/EmptyCardLoading.vue";
import { computed, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import useSpotifyMonthlyListenersFromMongoV2, {
  Status,
} from "@/common/composables/useSpotifyMonthlyListenersFromMongoV2";
import { getArtistGrowthBySpotifyID } from "@/api/mongoDB";
import { useStore } from "vuex";
import {
  checkIfValueGreaterThanZeroThenHumanize,
  checkIsValueAvailableOrNot,
  formatNumberIntoHumanizeForm,
} from "@/utils/staticHelper";

export default {
  name: "BaseForecastV2",
  components: {
    KpiCardV2,
    ForecastV2,
    EstimatedRevenuesV2,
    EmptyCardLoading,
  },
  props: {
    pdf: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const currentStatus = ref(Status.IDLE);
    const currentSpotifyMonthlyListeners = ref(0);
    const revenueLastMonths = ref(0);
    const loading = ref(false);
    const spotifyListeners = ref([]);
    const yearBackSpotifyMonthlyListeners = ref(0);
    const spotifyListenersMonth = ref([]);
    const growthRate = ref({
      growthRate: 0,
      followersIncreased: true,
      percentage: 80,
    });
    const assumedGrowthRate = ref(0);
    const years = ref(1);
    const months = ref(12);
    const monthlyMean = ref(false);
    const chartmetricSpotifyListenersGrowth = ref({
      "12Months": 0,
      "24Months": 0,
      "36Months": 0,
      monthlyMean: 0,
    });
    const route = useRoute();
    const store = useStore();
    const baseForecastRevenuesSum = computed(
      () => store.getters["ForecastModule/getBaseForecastRevenuesSum"]
    );
    onMounted(async () => {
      if (route.params.spotifyID) {
        await getSpotifyMonthlyListeners();
      }
    });
    const revenueUpdated = (value) => {
      revenueLastMonths.value = value;
    };
    const calculate36MonthAvg = (val) => {
      return checkIfValueGreaterThanZeroThenHumanize(val / 3) + "%";
    };
    const getSpotifyMonthlyListeners = async () => {
      try {
        loading.value = true;
        const { spotifyData, status } =
          await useSpotifyMonthlyListenersFromMongoV2(
            route.params.spotifyID,
            years.value,
            -1
          );
        const monthListeners = spotifyData.value.monthlyListeners.slice(0, 3);
        if (monthListeners.length) {
          const lastElementIndex = monthListeners.length - 1;
          currentSpotifyMonthlyListeners.value =
            monthListeners[lastElementIndex];
          yearBackSpotifyMonthlyListeners.value = monthListeners[0];
        }
        spotifyListeners.value = monthListeners;
        spotifyListenersMonth.value = spotifyData.value.months;
        currentStatus.value = status.value;
        await calculatePercentageChange();
      } catch (e) {
        console.log(e);
      } finally {
        loading.value = false;
      }
    };

    const calculatePercentageChange = async () => {
      try {
        let { data } = await getArtistGrowthBySpotifyID(route.params.spotifyID);
        if (monthlyMean.value === true) {
          growthRate.value.growthRate = data.chartmetricSpotifyListenersGrowth
            ? data.chartmetricSpotifyListenersGrowth["monthlyMean"]
            : 0;
        } else {
          growthRate.value.growthRate = data.chartmetricSpotifyListenersGrowth
            ? data.chartmetricSpotifyListenersGrowth["36Months"] / 3
            : 0;
        }
        chartmetricSpotifyListenersGrowth.value =
          data.chartmetricSpotifyListenersGrowth
            ? data.chartmetricSpotifyListenersGrowth
            : chartmetricSpotifyListenersGrowth.value;
        data = {};
      } catch (e) {
        console.log(e);
      }
    };
    //currently not in working according to client req.
    const yearsChanged = async (obj) => {
      years.value = obj.years;
      months.value = 12 * years.value;
      monthlyMean.value = obj.monthlyMean;
      revenueLastMonths.value = 0;
      await getSpotifyMonthlyListeners();
    };
    const assumedGrowth = (growth) => {
      assumedGrowthRate.value = formatNumberIntoHumanizeForm(growth);
    };
    return {
      loading,
      months,
      years,
      monthlyMean,
      currentSpotifyMonthlyListeners,
      chartmetricSpotifyListenersGrowth,
      yearBackSpotifyMonthlyListeners,
      spotifyListeners,
      currentStatus,
      Status,
      spotifyListenersMonth,
      revenueLastMonths,
      growthRate,
      assumedGrowthRate,
      baseForecastRevenuesSum,
      calculatePercentageChange,
      yearsChanged,
      revenueUpdated,
      formatNumberIntoHumanizeForm,
      assumedGrowth,
      checkIfValueGreaterThanZeroThenHumanize,
      checkIsValueAvailableOrNot,
      calculate36MonthAvg,
    };
  },
};
</script>
