<template>
  <el-row
    :gutter="12"
    id="revenue_past_12_months"
    v-if="showHtml"
    class="margin-bottom-12"
  >
    <el-col
      v-for="(data, index) in revenueCardData"
      :key="index"
      :lg="4"
      :md="8"
      :xs="8"
    >
      <div class="shadow-card card text-center h-100">
        <div class="card-body revenue-kpi-card">
          <h3
            :class="`text-${data.color}`"
            class="truncate"
            data-tag="h3"
            title=""
            data-tooltip="true"
            :data-text="'€ ' + formatNumberIntoHumanizeForm(data.value)"
          >
            € {{ formatNumberIntoHumanizeForm(data.value) }}
          </h3>
          <span class="subheading">{{ data.description }}</span>
        </div>
      </div>
    </el-col>
  </el-row>
  <div class="margin-bottom-12">
    <CardToolbar
      description="Estimation based on Spotify Listeners"
      :title="'Revenues past ' + months + ' months'"
      :fontStyle="true"
      :shadow-class="true"
      v-if="showHtml"
      :margin="false"
      :padding-left="true"
    >
      <template v-slot:toolbar>
        <button
          class="
            btn btn-sm btn-color-muted btn-active
            active
            btn-active-primary
            px-4
          "
          data-bs-toggle="modal"
          data-bs-target="#revenues_forecast_modal"
          @click="resetData"
        >
          Configure
        </button>
      </template>
      <template v-slot:body>
        <RevenueChart
          v-if="forecastValuesConfigured"
          :currency-values="true"
          :colors="['#5AD1A6', '#26C3FD', '#D54E8C']"
          :categories="revenueGraphCategories"
          :series="revenueGraphData"
          :stroke-width="[5, 0, 5]"
        />
      </template>
    </CardToolbar>
  </div>
  <ConfigureForecastModal
    @forecast-config="calculateEstimatedRevenue"
    :forecast-configuration-values="forecastConfigurationValues"
    id="revenues_forecast_modal"
    :scenarios="scenarios"
  />
</template>

<script>
import { defineComponent, onMounted, ref, watch } from "vue";

import CardToolbar from "../../common/components/CardToolbar";
import ConfigureForecastModal from "../../common/components/ConfigureForecastModal";

import RevenueChart from "../../common/components/RevenueChart";
import { formatNumberIntoHumanizeForm } from "@/utils/staticHelper";

export default defineComponent({
  name: "EstimatedRevenuesV2",
  components: { RevenueChart, CardToolbar, ConfigureForecastModal },
  props: {
    spotifyListeners: {
      type: Array,
      required: true,
    },
    spotifyListenersMonths: {
      type: Array,
      required: true,
    },
    months: {
      type: Number,
      required: true,
    },
    showHtml: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, context) {
    const forecastConfigurationValues = ref({
      averagePlays: {
        title: "Average number of play per monthly listener",
        type: "averagePlays",
        HigherBoundRange: 2.8,
        ["RevenueLast" + props.months + "Months"]: 2.2,
        LowerBoundRange: 1.6,
        min: 0,
        max: 10,
        step: 0.2,
      },
      spotifyShare: {
        title: "Market Share spotify vs. other Streamers",
        type: "spotifyShare",
        HigherBoundRange: 35,
        ["RevenueLast" + props.months + "Months"]: 40,
        LowerBoundRange: 50,
        min: 1,
        max: 100,
      },
      streamingShare: {
        title: "Market Share Streaming vs. Total Market",
        type: "streamingShare",
        HigherBoundRange: 75,
        ["RevenueLast" + props.months + "Months"]: 80,
        LowerBoundRange: 90,
        min: 1,
        max: 100,
      },
    });
    const forecastValuesConfigured = ref(false);
    const revenueCardData = ref([]);
    const revenueGraphData = ref([]);
    const revenueGraphCategories = ref([]);
    const scenarios = ref([
      "Higher Bound Range",
      "Revenue Last " + props.months + " Months",
      "Lower Bound Range",
    ]);

    onMounted(async () => {
      calculateEstimatedRevenue({
        scenarios: scenarios.value,
        forecastConfig: forecastConfigurationValues.value,
      });
    });

    const configureCardColor = (scenario) => {
      if (scenario.toLowerCase().includes("higher")) {
        return "success";
      } else if (scenario.toLowerCase().includes("lower")) {
        return "danger";
      } else {
        return "primary";
      }
    };

    const calculateEstimatedRevenue = ({ scenarios, forecastConfig }) => {
      scenarios.forEach((config) => {
        const scenarioName = config.replace(/\s/g, "");
        const configValues = {
          averagePlays: forecastConfig["averagePlays"][scenarioName],
          spotifyShare: forecastConfig["spotifyShare"][scenarioName],
          streamingShare: forecastConfig["streamingShare"][scenarioName],
        };
        const totalRevenue = calculateLast12MonthsRevenue(configValues, config);
        if (scenarioName === "RevenueLast" + props.months + "Months") {
          context.emit("revenueUpdated", totalRevenue);
        }
        if (props.showHtml) {
          revenueCardData.value.push({
            value: Math.round(totalRevenue / props.months),
            description: "Monthly Avg",
            color: configureCardColor(config),
          });
        }
      });
      forecastValuesConfigured.value = true;
    };

    const calculateLast12MonthsRevenue = (forecastValue, scenario) => {
      const data = props.spotifyListeners.map((totalYearlyFollowers) => {
        const totalSpotifyPlays =
          totalYearlyFollowers * forecastValue.averagePlays;
        const revenueSpotify = totalSpotifyPlays * 0.003;
        const revenuesAllStreamingPlatforms =
          (revenueSpotify / forecastValue.spotifyShare) * 100;
        const totalRevenue =
          (revenuesAllStreamingPlatforms / forecastValue.streamingShare) * 100;
        return Math.round(totalRevenue);
      });
      const totalRevenue = data.reduce(
        (previousValue, currentValue) => previousValue + currentValue,
        0
      );
      if (props.showHtml) {
        revenueCardData.value.push({
          value: totalRevenue,
          description: "Total Revenue",
          color: configureCardColor(scenario),
        });
        revenueGraphCategories.value = props.spotifyListenersMonths;
        revenueGraphData.value.push({
          name: scenario,
          type:
            scenario === "Revenue Last " + props.months + " Months"
              ? "bar"
              : "line",
          data,
        });
      }
      return totalRevenue;
    };

    const resetData = () => {
      forecastValuesConfigured.value = false;
      revenueGraphData.value = [];
      revenueCardData.value = [];
      revenueGraphCategories.value = [];
    };

    return {
      calculateEstimatedRevenue,
      forecastConfigurationValues,
      forecastValuesConfigured,
      revenueCardData,
      revenueGraphData,
      revenueGraphCategories,
      resetData,
      scenarios,
      formatNumberIntoHumanizeForm,
    };
  },
});
</script>

<style scoped>
.revenue-kpi-card {
  padding: 2rem 1.25rem;
}

.shadow-card:hover {
  box-shadow: 0 15px 10px -10px rgba(31, 31, 31, 0.5) !important;
  transition: all 0.3s ease !important;
}
</style>
