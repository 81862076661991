<template>
  <div class="marketing-investments mt-2">
    <el-row :gutter="12" class="margin-bottom-12">
      <el-col :lg="4" :md="4" :sm="4" :xs="4">
        <KpiCardV2
          :title="maxMarketingBudgets"
          :text="'Max. Marketing Budgets'"
          :padding="true"
          :pdf="pdf"
        />
      </el-col>
      <el-col :lg="4" :md="4" :sm="4" :xs="4">
        <KpiCardV2
          :title="formatNumber(totalOnGoingEarnings)"
          :text="'Total Ongoing Earnings'"
          :padding="true"
          :pdf="pdf"
        />
      </el-col>
      <el-col :lg="16" :md="16" :sm="16" :xs="16">
        <div class="shadow-card card kpi-card-body">
          <el-row :gutter="12" class="ps-4 pe-4">
            <el-col :lg="4" :md="4" :sm="4" :xs="4" class="border-right">
              <svg
                :height="pdf ? '64px' : '75px'"
                :width="pdf ? '64px' : '75px'"
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                viewBox="0 0 483.563 483.563"
                xml:space="preserve"
              >
                <g>
                  <polygon
                    style="fill: #b3daff"
                    points="483.563,221.781 148.61,221.781 148.61,93.171 0,241.781 148.61,241.781 148.61,261.781
              		483.563,261.781 	"
                  />
                  <polygon
                    style="fill: #99ceff"
                    points="0,241.781 148.61,390.392 148.61,241.781 	"
                  />
                </g>
              </svg>
            </el-col>
            <el-col
              :lg="5"
              :md="5"
              :sm="5"
              :xs="5"
              class="pt-4"
              v-for="(revenue, index) in revenues"
              :key="index"
            >
              <KpiCardV2
                :title="formatNumber(revenue)"
                :text="'Revenues Fy' + (index + 1)"
                :card="false"
                :pdf="pdf"
              />
            </el-col>
          </el-row>
        </div>
      </el-col>
    </el-row>
    <div class="margin-bottom-12">
      <CardToolbar
        :description="''"
        :title="'Our entry investment & your ongoing earnings'"
        :fontStyle="true"
        :padding="false"
        :shadow-class="true"
        :show-toolbar-buttons="false"
        :margin="false"
      >
        <!--        <template v-slot:toolbar>-->
        <!--          <a-->
        <!--            class="-->
        <!--              btn btn-sm btn-color-muted btn-active-->
        <!--              active-->
        <!--              btn-active-primary-->
        <!--              px-4-->
        <!--              ms-1-->
        <!--            "-->
        <!--            data-bs-toggle="modal"-->
        <!--            data-bs-target="#marketing_investment_modal"-->
        <!--            >Configure</a-->
        <!--          >-->
        <!--        </template>-->
        <template v-slot:body>
          <el-row :gutter="20">
            <el-col :lg="12" :md="12" :sm="12" :xs="12">
              <div class="text-center theme-v2-color mt-6 ms-8">
                <el-row :gutter="12">
                  <el-col :lg="6" :md="6" :sm="6" :xs="6"></el-col>
                  <el-col
                    :lg="12"
                    :md="12"
                    :sm="12"
                    :xs="12"
                    class="p-8 dotted-border-kpi-card"
                  >
                    <KpiCardV2
                      :title="formatNumber(purchasePrice)"
                      :text="'Purchase Price / Cash Now'"
                      :card="false"
                      :tooltipText="false"
                      :pdf="pdf"
                    />
                  </el-col>
                  <el-col :lg="6" :md="6" :sm="6" :xs="6"></el-col>
                </el-row>
                <el-row :gutter="12">
                  <el-col :lg="10" :md="10" :sm="10" :xs="10">
                    <div class="investment-kpi-card p-8 mt-16">
                      <KpiCardV2
                        :title="calculateProposedCatalogueValuation"
                        :text="'Proposed Catalogue Valuation'"
                        :card="false"
                        :tooltipText="false"
                        :pdf="pdf"
                      />
                    </div>
                  </el-col>
                  <el-col :lg="4" :md="4" :sm="4" :xs="4">
                    <div class="d-flex flex-column">
                      <div>
                        <svg
                          height="90px"
                          width="60px"
                          version="1.1"
                          id="Layer_1"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          viewBox="0 0 483.284 483.284"
                          xml:space="preserve"
                        >
                          <g>
                            <polygon
                              style="fill: #b3daff"
                              points="241.644,88.284 241.644,0 153.358,88.284 221.643,88.284 221.643,483.284 261.643,483.284
		261.643,88.284 	"
                            />
                            <polygon
                              style="fill: #99ceff"
                              points="241.644,88.284 329.926,88.284 241.644,0 	"
                            />
                          </g>
                        </svg>
                      </div>
                      <div class="fw-boldest" style="font-size: 30px">X</div>
                    </div>
                  </el-col>
                  <el-col :lg="10" :md="10" :sm="10" :xs="10">
                    <div class="investment-kpi-card p-8 mt-16">
                      <KpiCardV2
                        :title="marketingInvestmentPercentages[0].value + '%'"
                        :text="'BlingRock Ownership'"
                        :card="false"
                        :tooltipText="false"
                        :pdf="pdf"
                      />
                    </div>
                  </el-col>
                </el-row>
              </div>
            </el-col>
            <el-col :lg="12" :md="12" :sm="12" :xs="12">
              <RevenueChart
                :chart-type="'bar'"
                :series="series"
                :colors="['#009EF7']"
                :currency-values="true"
                :categories="categories"
                :stroke-width="[0, 10]"
                :plot-options="{
                  bar: {
                    horizontal: false,
                    columnWidth: '80%',
                    startingShape: 'flat',
                  },
                }"
                :stacked="false"
                :chart-class="true"
              />
            </el-col>
          </el-row>
        </template>
      </CardToolbar>
    </div>
    <div :style="pdf ? 'margin-top: 12rem' : ''">
      <CardToolbar
        :description="''"
        :title="'Joint (100%) Investment Exit in 4 years'"
        :fontStyle="true"
        :padding="false"
        :shadow-class="true"
        :show-toolbar-buttons="false"
      >
        <template v-slot:body>
          <el-row :gutter="20" class="mt-6 ms-8 mb-8">
            <el-col :lg="14" :md="14" :sm="14" :xs="14">
              <el-row class="text-center theme-v2-color" :gutter="20">
                <el-col :lg="10" :md="10" :sm="10" :xs="10">
                  <div class="mb-4 p-8 investment-kpi-card">
                    <kpi-card-v2
                      :card="false"
                      :title="formatNumber(revenueFY4)"
                      :text="'Revenue Fy4'"
                      :pdf="pdf"
                    ></kpi-card-v2>
                  </div>
                </el-col>
                <el-col :lg="4" :md="4" :sm="4" :xs="4"></el-col>
                <el-col :lg="10" :md="10" :sm="10" :xs="10"></el-col>
              </el-row>
              <el-row class="text-center theme-v2-color" :gutter="20">
                <el-col :lg="10" :md="10" :sm="10" :xs="10">
                  <div class="mb-4 p-8 investment-kpi-card">
                    <kpi-card-v2
                      :card="false"
                      :title="yearlyRevenueMultiple[0].value + '.0 x'"
                      :text="yearlyRevenueMultiple[0].name"
                      :pdf="pdf"
                    ></kpi-card-v2>
                  </div>
                </el-col>
                <el-col :lg="4" :md="4" :sm="4" :xs="4"></el-col>
                <el-col :lg="10" :md="10" :sm="10" :xs="10">
                  <div class="mb-4 p-8 investment-kpi-card">
                    <kpi-card-v2
                      :card="false"
                      :title="formatNumber(purchasePrice)"
                      :text="'Cash Now'"
                      :pdf="pdf"
                    ></kpi-card-v2>
                  </div>
                </el-col>
              </el-row>
              <el-row class="text-center theme-v2-color" :gutter="20">
                <el-col :lg="10" :md="10" :sm="10" :xs="10">
                  <div class="mb-4 p-8 investment-kpi-card">
                    <kpi-card-v2
                      :card="false"
                      :title="formatNumber(valuationOfCatalogueEndOfFY4)"
                      :text="'Valuation of Catalogue end of FY4'"
                      :pdf="pdf"
                    ></kpi-card-v2>
                  </div>
                </el-col>
                <el-col :lg="4" :md="4" :sm="4" :xs="4"></el-col>
                <el-col :lg="10" :md="10" :sm="10" :xs="10">
                  <div class="mb-4 p-8 investment-kpi-card">
                    <kpi-card-v2
                      :card="false"
                      :title="formatNumber(totalOnGoingEarnings)"
                      :text="'Ongoing Earnings'"
                      :pdf="pdf"
                    ></kpi-card-v2>
                  </div>
                </el-col>
              </el-row>
              <el-row class="text-center theme-v2-color mt-8" :gutter="20">
                <el-col
                  :lg="10"
                  :md="10"
                  :sm="10"
                  :xs="10"
                  class="dotted-border-kpi-card p-8"
                >
                  <kpi-card-v2
                    :card="false"
                    :title="formatNumber(artistShareOfExitProceeds)"
                    :text="'Artist Share Exit Proceeds'"
                    :pdf="pdf"
                  ></kpi-card-v2>
                </el-col>
                <el-col :lg="4" :md="4" :sm="4" :xs="4"></el-col>
                <el-col
                  :lg="10"
                  :md="10"
                  :sm="10"
                  :xs="10"
                  class="dotted-border-kpi-card p-8"
                >
                  <kpi-card-v2
                    :card="false"
                    :title="formatNumber(totalArtistProceeds)"
                    :text="'Total Artist Proceeds'"
                    :pdf="pdf"
                  ></kpi-card-v2>
                </el-col>
              </el-row>
            </el-col>
            <el-col :lg="4" :md="4" :sm="4" :xs="4"></el-col>
            <el-col :lg="6" :md="6" :sm="6" :xs="6">
              <RevenueChart
                :labels-rotate="false"
                :chart-height="pdf ? 500 : 550"
                :y-axis-show="false"
                :chart-type="'bar'"
                :series="investmentExitSeries"
                :colors="['#009EF7']"
                :currency-values="true"
                :categories="['Artist alone', 'with BlingRock']"
                :stroke-width="[0, 10]"
                :plot-options="{
                  bar: {
                    horizontal: false,
                    startingShape: 'flat',
                    dataLabels: { position: 'top' },
                  },
                }"
                :data-labels="{
                  enabled: true,
                  formatter: function (val) {
                    return formatNumberIntoHumanizeForm(val, 0);
                  },
                  style: {
                    colors: ['rgb(161, 165, 183)'],
                    fontSize: '12px',
                  },
                  offsetY: -30,
                }"
                :stacked="false"
                :chart-class="true"
              />
            </el-col>
          </el-row>
        </template>
      </CardToolbar>
    </div>
  </div>
</template>
<script>
import { computed, ref, watch } from "vue";
import KpiCardV2 from "@/common/components/KpiCardV2.vue";
import CardToolbar from "@/common/components/CardToolbar.vue";
import RevenueChart from "@/common/components/RevenueChart.vue";
import { useStore } from "vuex";
import {
  checkIfValueGreaterThanZeroThenHumanize,
  chunkArrayIntoEqualSizes,
  formatNumberIntoHumanizeForm,
  sumArrayElements,
} from "@/utils/staticHelper";

export default {
  name: "MarketingInvestmentsV2",
  components: {
    KpiCardV2,
    CardToolbar,
    RevenueChart,
  },
  props: {
    pdf: {
      type: Boolean,
      default: false,
    },
    percentages: {
      type: Array,
      required: true,
    },
    multiples: {
      type: Array,
      required: true,
    },
  },
  setup(props, context) {
    const revenues = ref([]);
    const series = ref([
      {
        name: "Artist Ongoing Earnings",
        data: [],
      },
    ]);
    const categories = ref([
      "Q1",
      "Q2",
      "Q3",
      "Q4",
      "Q5",
      "Q6",
      "Q7",
      "Q8",
      "Q9",
      "Q10",
      "Q11",
      "Q12",
      "Q13",
      "Q14",
      "Q15",
      "Q16",
    ]);
    const marketingInvestmentPercentages = ref(props.percentages);
    const yearlyRevenueMultiple = ref(props.multiples);
    const investmentExitSeries = ref([{ name: "Investment exit", data: [] }]);
    const revenueFY4 = ref(0);
    const valuationOfCatalogueEndOfFY4 = ref(0);
    const artistShareOfExitProceeds = ref(0);
    const purchasePrice = ref(0);
    const totalOnGoingEarnings = ref(0);
    const totalArtistProceeds = ref(0);
    const store = useStore();
    const maxMarketingBudgets = computed(() =>
      checkIfValueGreaterThanZeroThenHumanize(
        store.getters["ForecastModule/getMaxMarketingBudgets"],
        0
      )
    );
    const marketingForecasts = computed(
      () => store.getters["ForecastModule/getMarketingForecasts"]
    );
    const lastYearRevenue = computed(
      () => store.getters["ForecastModule/getForecastLastYearRevenue"]
    );
    const calculateProposedCatalogueValuation = computed(() => {
      return checkIfValueGreaterThanZeroThenHumanize(
        marketingInvestmentPercentages.value[1].value * lastYearRevenue.value,
        0
      );
    });
    const calculateMarketingInvestments = (forecasts) => {
      series.value[0].data = [];
      revenues.value = [];
      forecasts.map((e) => {
        series.value[0].data.push(
          e * (100 / 100 - marketingInvestmentPercentages.value[0].value / 100)
        );
      });
      const dividedArray = chunkArrayIntoEqualSizes(series.value[0].data, 4);
      for (const data of dividedArray) {
        revenues.value.push(sumArrayElements(data));
      }
      totalOnGoingEarnings.value = sumArrayElements(revenues.value);
      configureInvestmentExits();
    };
    const calculatePurchaseValue = () => {
      purchasePrice.value =
        (marketingInvestmentPercentages.value[0].value / 100) *
        (marketingInvestmentPercentages.value[1].value * lastYearRevenue.value);
      configureInvestmentExits();
    };
    const configure = () => {
      calculateMarketingInvestments(marketingForecasts.value);
      calculatePurchaseValue();
    };
    const configureInvestmentExits = (val) => {
      investmentExitSeries.value[0].data = [];
      if (val) {
        yearlyRevenueMultiple.value = val;
      }
      valuationOfCatalogueEndOfFY4.value =
        revenueFY4.value * yearlyRevenueMultiple.value[0].value;
      artistShareOfExitProceeds.value =
        valuationOfCatalogueEndOfFY4.value *
        ((100 - marketingInvestmentPercentages.value[0].value) / 100);
      totalArtistProceeds.value =
        purchasePrice.value +
        totalOnGoingEarnings.value +
        artistShareOfExitProceeds.value;
      investmentExitSeries.value[0].data = [
        lastYearRevenue.value * 4,
        totalArtistProceeds.value,
      ];
    };
    const formatNumber = (val) => {
      return checkIfValueGreaterThanZeroThenHumanize(val, 0);
    };

    // for calling child function from parent
    context.expose({ configure });

    watch(
      () => marketingForecasts.value,
      (values) => {
        if (values.length > 0) {
          revenueFY4.value = sumArrayElements(values.slice(-4));
          calculateMarketingInvestments(values);
        }
      }
    );
    watch(
      () => lastYearRevenue.value,
      (value) => {
        if (value) {
          calculatePurchaseValue();
        }
      }
    );
    return {
      revenues,
      marketingForecasts,
      series,
      categories,
      maxMarketingBudgets,
      marketingInvestmentPercentages,
      purchasePrice,
      lastYearRevenue,
      calculateProposedCatalogueValuation,
      totalOnGoingEarnings,
      yearlyRevenueMultiple,
      revenueFY4,
      valuationOfCatalogueEndOfFY4,
      artistShareOfExitProceeds,
      totalArtistProceeds,
      investmentExitSeries,
      formatNumberIntoHumanizeForm,
      sumArrayElements,
      configure,
      configureInvestmentExits,
      formatNumber,
    };
  },
};
</script>
<style lang="scss">
.investment-kpi-card {
  background: aliceblue;
  border-radius: 12px;
}

.dotted-border-kpi-card {
  border: 3px dotted rgba(0, 158, 247, 0.85);
  border-radius: 12px;
}
</style>
