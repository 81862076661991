<template>
  <el-row
    id="artist_forecast"
    :gutter="12"
    class="margin-bottom-12"
    v-if="showExitEvaluationRevenueCard"
  >
    <el-col
      :xs="24"
      :lg="8"
      v-for="(revenueData, index) in revenueCardData"
      :key="index"
    >
      <RevenueCardV2
        :roi="revenueData.roi"
        :color="configureCardColor(revenueData.scenario)"
        :current-revenue="revenueData.currentRevenue"
        :predicted-revenue="revenueData.predictedRevenue"
        :compound-annual-growth-rate="revenueData.compoundAnnualGrowthRate"
        :exit-multiple="revenueData.exitMultiple"
        :exit-valuation="revenueData.exitValuation"
      />
    </el-col>
  </el-row>
  <EmptyCardLoading
    v-if="loading"
    :loading="loading"
    :type="'skeleton'"
    :card-height="'350px'"
    :rows="7"
    class="mb-5 mb-xl-10"
  />

  <CardToolbar
    :description="description"
    :title="title"
    :fontStyle="true"
    :padding="false"
    :shadow-class="true"
    :show-toolbar-buttons="showButtons"
    v-if="forecastValuesConfigured && graphSeriesData.length > 0 && !loading"
  >
    <template v-slot:body>
      <RevenueChart
        :chart-type="chartType"
        :series="graphSeriesData"
        :colors="colors"
        :currency-values="true"
        :categories="graphCategoriesData"
        :stroke-width="strokeWidth"
        :plot-options="plotOptions"
        :stacked="stacked"
        :chart-class="chartClass"
      />
    </template>
  </CardToolbar>
  <!--  <ConfigureForecastModal-->
  <!--    @forecast-config="calculateForecastValues"-->
  <!--    :forecast-values-configured="forecastValuesConfigured"-->
  <!--    :forecast-configuration-values="forecastConfigurationValues"-->
  <!--    id="artist_forecast_modal"-->
  <!--    :scenarios="scenarios"-->
  <!--    :show-exit-evaluation-revenue-card="showExitEvaluationRevenueCard"-->
  <!--    :modal-title="modalTitle"-->
  <!--  />-->
</template>

<script>
/* eslint-disable */
import {computed, ref, watch} from "vue";
// import ConfigureForecastModal from "./ConfigureForecastModal";
import CardToolbar from "./CardToolbar";
import RevenueChart from "./RevenueChart";
import RevenueCardV2 from "./RevenueCardV2";
import {useStore} from "vuex";
import EmptyCardLoading from "@/common/components/EmptyCardLoading.vue";

export default {
  name: "ForecastV2",
  components: {
    CardToolbar,
    RevenueCardV2,
    RevenueChart,
    EmptyCardLoading
    // ConfigureForecastModal,
  },
  props: {
    showButtons: {
      type: Boolean,
      default: true
    },
    currentSpotifyFollowers: {
      type: Number,
      required: true,
    },
    revenueLastMonths: {
      type: Number,
      required: true,
    },
    spotifyFollowersAYearAgo: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      default: "Forecast",
    },
    description: {
      type: String,
      default: "",
    },
    growthRate: {
      type: Object,
      required: true
    },
    selectedYears: {
      type: Number,
      required: true
    },
    monthlyMean: {
      type: Boolean,
      required: true
    },
    showExitEvaluationRevenueCard: {
      type: Boolean,
      default: true
    },
    chartType: {
      type: String,
      default: 'line'
    },
    plotOptions: {
      type: Object,
      default: () => {
        return {};
      },
    },
    stacked: {
      type: Boolean,
      default: false
    },
    strokeWidth: {
      type: Array,
      default: [5, 5, 5]
    },
    chartClass: {
      type: Boolean,
      default: false,
    },
    categoriesTypeYearly: {
      type: Boolean,
      default: true
    },
    modalTitle: {
      type: String,
      default: 'Configure Forecast'
    },
    colors: {
      type: Array,
      required: true
    }
  },
  setup(props, context) {
    const store = useStore();
    const growthRate = ref(props.growthRate);
    const forecastValuesConfigured = ref(false);
    const graphSeriesData = ref([]);
    const graphCategoriesData = ref([]);
    const revenueCardData = ref([]);
    const configureForecastModalButton = ref(null);
    const forecastConfigurationValues = ref({});
    const currentYear = ref(new Date().getFullYear());
    const years = ref(3);
    const loading = ref(false);

    forecastConfigurationValues.value.exitMultiple = {
      title: "Exit Multiple",
      type: "exitMultiple",
      positive: 14,
      medium: 14,
      negative: 14,
      min: 0,
      max: 30,
      step: 1,
      show: props.showExitEvaluationRevenueCard
    };
    forecastConfigurationValues.value.streamingShare = {
      title: "Growth Rates",
      type: "streamingShare",
      positive: 4,
      medium: 8,
      negative: 4,
      min: 0,
      max: 100,
      step: 1,
      show: true
    };
    const scenarios = ref(["positive", "medium", "negative"]);

    const baseForecastConfigurationPercentages = computed(
        () =>
            store.getters["ForecastModule/getBaseForecastConfigurationPercentages"]
    );

    const changeYearValue = (year) => {
      years.value = year;
      context.emit("yearsChanged", {years: years.value, monthlyMean: false});
    };
    const getMonthlyMeanGrowth = () => {
      years.value = 3;
      if (props.monthlyMean === false) {
        context.emit("yearsChanged", {years: years.value, monthlyMean: true});
      }

    }
    const assumedGrowth = (val) => {
      context.emit("assumedGrowth", val);
    }

    const configureCardColor = (scenario) => {
      if (scenario.includes("positive")) {
        return "success";
      } else if (scenario.includes("medium")) {
        return "primary";
      } else {
        return "danger";
      }
    };

    const entryData = computed(() => {
      return {
        revenueLastMonths: props.revenueLastMonths,
        growthRate: growthRate.value,
      };
    });

    const resetData = () => {
      forecastValuesConfigured.value = false;
      graphSeriesData.value = [];
      graphCategoriesData.value = [];
      revenueCardData.value = [];
    };

    const calculateForecastValues = ({scenarios, forecastConfig}) => {
      resetData();
      assumedGrowth(forecastConfig["streamingShare"]["positive"]);
      scenarios.forEach((config) => {
        const configValues = {
          streamingShare: forecastConfig["streamingShare"][config],
          exitMultiple: forecastConfig["exitMultiple"][config],
        };
        const currentRevenue = props.revenueLastMonths;
        const growthRateLocal = calculateGrowthRate(
            forecastConfig["streamingShare"][config]
        );
        const predictedRevenue = calculateNext10YearsRevenue(
            currentRevenue,
            growthRateLocal,
            config
        );
        const compoundAnnualGrowthRate = growthRateLocal;
        const exitValuation = calculateExitValuation(
            predictedRevenue,
            configValues.exitMultiple
        );
        const roi = calculateROI(
            currentRevenue,
            configValues.exitMultiple,
            exitValuation
        );
        revenueCardData.value.push({
          scenario: config,
          currentRevenue,
          predictedRevenue,
          compoundAnnualGrowthRate,
          exitValuation,
          exitMultiple: configValues.exitMultiple,
          roi,
        });
      });
      setTimeout(() => forecastValuesConfigured.value = true, 1000);
      setTimeout(() => loading.value = false, 1000);
    };

    const calculateROI = (lastYearRevenue, exitMultiple, exitValuation) => {
      return ((exitValuation / (lastYearRevenue * 5)) * 100).toFixed(0);
    };

    const calculateExitValuation = (predictedRevenue, exitMultiple) => {
      return predictedRevenue * exitMultiple;
    };

    const calculateNext10YearsRevenue = (revenue, growthRate, scenario) => {
      const data = [];
      const categories = [];
      let predictedRevenue = revenue;
      let loopCounter = 10;
      if (!props.categoriesTypeYearly) {
        loopCounter = 16;
      }
      for (let i = 0; i < loopCounter; i++) {
        predictedRevenue += Math.trunc(revenue * (growthRate / 100));
        data.push(predictedRevenue);
        categories.push(props.categoriesTypeYearly ? i + currentYear.value : 'Q' + (i + 1));
      }
      graphCategoriesData.value = Array.from(new Set(categories));
      if (scenario === 'medium') {
        if (!props.categoriesTypeYearly) {
          store.dispatch('ForecastModule/setBaseForecastRevenues', data);
        }
      }
      graphSeriesData.value.push({
        name: changeScenarioName(scenario),
        type: props.chartType,
        data: data,
      });
      return predictedRevenue;
    };

    const changeScenarioName = (scenario) => {
      if (scenario === "positive" && !props.categoriesTypeYearly) {
        return "Assumed YOY Growth Rate";
      } else if (scenario === "positive" && props.categoriesTypeYearly) {
        return "50% of Artist Growth";
      } else if (scenario === "medium") {
        return "Market Growth";
      } else if (scenario === "negative") {
        return "50% of Market Growth";
      }
    };
    const calculateGrowthRate = (config) => {
      return config;
    };
    watch(
        () => entryData.value,
        (value) => {
          loading.value = true;
          if (value.growthRate.growthRate > 0) {
            forecastConfigurationValues.value.streamingShare.positive =
                Number((value.growthRate.growthRate * (value.growthRate.percentage / 100)).toFixed(1)) > 0 ? Number((value.growthRate.growthRate * (value.growthRate.percentage / 100)).toFixed(1)) : 1;
          } else {
            forecastConfigurationValues.value.streamingShare.positive =
                Number((value.growthRate.growthRate * (value.growthRate.percentage / 100)).toFixed(1)) > 0 ? Number((value.growthRate.growthRate * (value.growthRate.percentage / 100)).toFixed(1)) : 1;
            forecastConfigurationValues.value.streamingShare.min =
                Number((value.growthRate.growthRate * (value.growthRate.percentage / 100)).toFixed(1)) > 0 ? Number((value.growthRate.growthRate * (value.growthRate.percentage / 100)).toFixed(1)) : 1;
          }
          store.dispatch("ForecastModule/setBaseForecastConfigurationValues", {
            values: forecastConfigurationValues.value,
            status: false
          });
          if (value.revenueLastMonths) {
            calculateForecastValues({
              scenarios: scenarios.value,
              forecastConfig: forecastConfigurationValues.value,
            });
          }
        },
        {immediate: true, deep: true}
    );

    watch(
        () => baseForecastConfigurationPercentages.value,
        (value) => {
          if (value)
            if (value.status) {
              forecastValuesConfigured.value = false;
              loading.value = true;
              forecastConfigurationValues.value = value.values;
              calculateForecastValues({
                scenarios: scenarios.value,
                forecastConfig: forecastConfigurationValues.value,
              });
            }
        }
    );
    return {
      loading,
      years,
      configureForecastModalButton,
      forecastValuesConfigured,
      forecastConfigurationValues,
      graphSeriesData,
      graphCategoriesData,
      revenueCardData,
      scenarios,
      currentYear,
      changeScenarioName,
      changeYearValue,
      getMonthlyMeanGrowth,
      calculateForecastValues,
      configureCardColor,
      resetData,
    };
  },
};
/* eslint-disable */
</script>

<style scoped>
</style>
