<template>
  <div class="shadow-card card">
    <div class="card-body">
      <el-row class="text-center mb-8">
        <el-col :xs="24" :md="12">
          <h3
            :class="`text-${color}`"
            class="revenue truncate"
            data-tag="h3"
            title=""
            data-tooltip="true"
            :data-text="'€ ' + formatNumberIntoHumanizeForm(exitValuation)"
          >
            € {{ formatNumberIntoHumanizeForm(exitValuation) }}
          </h3>
          <span class="subheading">Exit VALUATION</span>
        </el-col>
        <el-col :xs="24" :md="12">
          <h3
            :class="`text-${color}`"
            class="revenue truncate"
            data-tag="h3"
            title=""
            data-tooltip="true"
            :data-text="formatNumberIntoHumanizeForm(roi) + '%'"
          >
            {{ formatNumberIntoHumanizeForm(roi) }}%
          </h3>
          <span class="subheading">ROI</span>
        </el-col>
      </el-row>
      <div class="d-flex justify-content-between">
        <span class="revenue-info-subheading">Revenues Now</span>
        <span
          class="revenue-info-text truncate"
          data-tag="span"
          title=""
          data-tooltip="true"
          :data-text="'€ ' + formatNumberIntoHumanizeForm(currentRevenue)"
          >€ {{ formatNumberIntoHumanizeForm(currentRevenue) }}</span
        >
      </div>
      <div class="d-flex justify-content-between">
        <span class="subheading revenue-info-subheading"
          >Revenue {{ get10YearsAhead() }}</span
        >
        <span
          class="revenue-info-text truncate"
          data-tag="span"
          title=""
          data-tooltip="true"
          :data-text="'€ ' + formatNumberIntoHumanizeForm(predictedRevenue)"
          >€ {{ formatNumberIntoHumanizeForm(predictedRevenue) }}</span
        >
      </div>
      <div class="d-flex justify-content-between">
        <span class="revenue-info-subheading">Cagr</span>
        <span
          class="revenue-info-text truncate"
          data-tag="span"
          title=""
          data-tooltip="true"
          :data-text="
            formatNumberIntoHumanizeForm(compoundAnnualGrowthRate) + '%'
          "
        >
          {{ formatNumberIntoHumanizeForm(compoundAnnualGrowthRate) }}%
        </span>
      </div>
      <div class="d-flex justify-content-between">
        <span class="revenue-info-subheading">Exit Multiple</span>
        <span
          class="revenue-info-text truncate"
          data-tag="span"
          title=""
          data-tooltip="true"
          :data-text="exitMultiple + 'X'"
          >{{ exitMultiple }}X</span
        >
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import { formatNumberIntoHumanizeForm } from "@/utils/staticHelper";

export default {
  name: "RevenueCard",
  props: {
    color: {
      type: String,
      required: true,
    },
    currentRevenue: {
      type: Number,
      required: true,
    },
    predictedRevenue: {
      type: Number,
      required: true,
    },
    compoundAnnualGrowthRate: {
      type: Number,
      required: true,
    },
    exitMultiple: {
      type: Number,
      required: true,
    },
    exitValuation: {
      type: Number,
      required: true,
    },
    roi: {
      type: Number,
      required: true,
    },
  },
  setup() {
    const get10YearsAhead = () => {
      return moment().add(10, "years").format("yyyy");
    };

    return { get10YearsAhead, formatNumberIntoHumanizeForm };
  },
};
</script>

<style scoped>
.revenue-info-subheading {
  font-size: 10px !important;
  color: #33647f !important;
  margin-bottom: 0.5rem !important;
}

.revenue-info-text {
  color: #33647f !important;
  font-weight: 700;
  font-size: 10px;
  margin-bottom: 0.5rem !important;
}

.shadow-card:hover {
  box-shadow: 0 15px 10px -10px rgba(31, 31, 31, 0.5) !important;
  transition: all 0.3s ease !important;
}
</style>
