<template>
  <div
    class="artist-forecast-v2 d-flex justify-content-start"
    id="artist_forecast_v2"
  >
    <div class="forecast-main-content w-100">
      <sub-top-nav-bar-v2
        current-active-tab="#forecast_historic_revenues"
        main-id="forecast_menu_list"
        :tabs-list="tabMenuList"
        :have-sub-list="false"
        current-active-sub-list=""
        :sub-list="[]"
        :scroll-to-element-margin-main="80"
        sub-id="forecast_sub_id"
        :pdf="true"
        :loading="loading"
        @generateReport="generateReport"
      />
      <div class="row mt-8">
        <div id="forecast_historic_revenues">
          <div class="artist-content-heading">Previous growth & revenues</div>
          <HistoricRevenueV2 />
        </div>
        <div id="base_forecast">
          <div class="d-flex justify-content-between">
            <div class="artist-content-heading">Base Forecast</div>
            <div>
              <a
                class="
                  btn btn-sm btn-color-muted btn-active
                  active
                  btn-active-primary
                  px-4
                  ms-1
                "
                data-bs-toggle="modal"
                data-bs-target="#configure_forecast_modal"
                >Configure</a
              >
            </div>
          </div>
          <BaseForecastV2 />
        </div>
        <div id="forecast_marketing_budgets">
          <div class="artist-content-heading">Marketing Budgets</div>
          <MarketingBudgetV2
            ref="marketingBudgetChildRef"
            :percentages="yearlyMarketingBudgetsPercentages"
          />
        </div>
        <div id="forecast_marketing">
          <div class="artist-content-heading">Marketing Forecast</div>
          <MarketingForecastV2
            ref="marketingForecastChildRef"
            :percentages="marketingForecastPercentages"
          />
        </div>
        <div id="forecast_investment">
          <div class="artist-content-heading">Investment</div>
          <MarketingInvestmentsV2
            ref="marketingInvestmentChildRef"
            :percentages="marketingInvestmentPercentages"
            :multiples="yearlyRevenueMultiple"
          />
        </div>
      </div>
      <vue3-html2pdf
        :show-layout="false"
        :float-layout="true"
        :enable-download="true"
        :preview-modal="false"
        :paginate-elements-by-height="1000"
        :pdf-quality="2"
        :manual-pagination="true"
        pdf-format="a4"
        pdf-orientation="landscape"
        :filename="$route.params.artistName"
        pdf-content-width="1050px"
        ref="forecastSectionPdf"
        @progress="onProgress($event)"
      >
        <template v-slot:pdf-content>
          <div class="row">
            <div
              class="text-center mt-10 theme-color fw-boldest"
              style="font-size: 40px"
            >
              {{ $route.params.artistName }}
            </div>
            <div
              id="forecast_historic_revenues"
              class="html2pdf__page-break ms-10 mt-8"
            >
              <div class="artist-content-heading">
                Previous growth & revenues
              </div>
              <HistoricRevenueV2 :pdf="true" />
            </div>
            <div
              id="base_forecast"
              class="html2pdf__page-break ms-10 pdf-top-margin"
            >
              <div class="artist-content-heading">Base Forecast</div>
              <BaseForecastV2 :pdf="true" />
            </div>
            <div
              id="forecast_marketing_budgets"
              class="html2pdf__page-break pdf-top-margin ms-10"
            >
              <div class="artist-content-heading">Marketing Budgets</div>
              <MarketingBudgetV2
                :pdf="true"
                :percentages="yearlyMarketingBudgetsPercentages"
              />
            </div>
            <div
              id="forecast_marketing"
              class="html2pdf__page-break ms-10 pdf-top-margin"
            >
              <div class="artist-content-heading">Marketing Forecast</div>
              <MarketingForecastV2
                :pdf="true"
                :percentages="marketingForecastPercentages"
              />
            </div>
            <div id="forecast_investment" class="ms-10 pdf-top-margin">
              <div class="artist-content-heading">Investment</div>
              <MarketingInvestmentsV2
                :pdf="true"
                :percentages="marketingInvestmentPercentages"
                :multiples="yearlyRevenueMultiple"
              />
            </div>
          </div>
        </template>
      </vue3-html2pdf>
    </div>
  </div>
  <div
    class="modal fade"
    id="configure_forecast_modal"
    tabindex="-1"
    aria-hidden="true"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
  >
    <div class="modal-dialog modal-dialog-centered mw-1000px">
      <div class="modal-content bg-light-grey">
        <div class="modal-header" id="kt_modal_create_api_key_header">
          <h2>Forecast Configuration</h2>
        </div>
        <div class="modal-body scroll-y py-0 mt-8">
          <el-row :gutter="20" class="d-flex justify-content-evenly">
            <el-col
              v-for="(forecastObject, index) in forecastConfigurationValues"
              :key="index"
              :span="24"
            >
              <ForecastSliderCard
                v-if="forecastObject.show"
                v-model:value="forecastConfigurationValues[index]"
                :forecast-object="forecastObject"
                :scenarios="scenarios"
                :change-scenario="false"
              />
            </el-col>
          </el-row>
          <el-row :gutter="20" class="d-flex justify-content-evenly">
            <el-col :span="12">
              <div class="card mb-7">
                <div class="card-body">
                  <h4 class="heading">Base Budget Percentage</h4>
                  <div
                    class="
                      d-flex
                      justify-content-between
                      align-items-center
                      py-1
                    "
                    v-for="(base, index) in yearlyMarketingBudgetsPercentages"
                    :key="index"
                  >
                    <span class="w-50 fw-bold">FY {{ index + 1 }}</span>
                    <div class="w-75 d-flex align-items-center">
                      <span class="slider-value">{{ base.base_budget }}</span>
                      <Slider
                        class="w-100"
                        v-model:value="base.base_budget"
                        :value="base.base_budget"
                        :min="0"
                        :max="100"
                        :step="1"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="card mb-7">
                <div class="card-body">
                  <h4 class="heading">Upside Budget Percentage</h4>
                  <div
                    class="
                      d-flex
                      justify-content-between
                      align-items-center
                      py-1
                    "
                    v-for="(upside, index) in yearlyMarketingBudgetsPercentages"
                    :key="index"
                  >
                    <span class="w-50 fw-bold">FY {{ index + 1 }}</span>
                    <div class="w-75 d-flex align-items-center">
                      <span class="slider-value">{{
                        upside.upside_budget
                      }}</span>
                      <Slider
                        class="w-100"
                        v-model:value="upside.upside_budget"
                        :value="upside.upside_budget"
                        :min="0"
                        :max="100"
                        :step="1"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="20" class="d-flex justify-content-evenly">
            <el-col :span="24">
              <div class="card mb-7">
                <div class="card-body">
                  <h4 class="heading">Marketing Forecast</h4>
                  <div
                    class="
                      d-flex
                      justify-content-between
                      align-items-center
                      py-1
                    "
                    v-for="(configure, index) in marketingForecastPercentages"
                    :key="index"
                  >
                    <span class="w-50 fw-bold">{{ configure.name }} </span>
                    <div class="w-75 d-flex align-items-center">
                      <span class="slider-value">{{ configure.value }}</span>
                      <Slider
                        class="w-100"
                        v-model:value="configure.value"
                        :value="configure.value"
                        :min="configure.min"
                        :max="configure.max"
                        :step="configure.step"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="20" class="d-flex justify-content-evenly">
            <el-col :span="24">
              <div class="card mb-7">
                <div class="card-body">
                  <h4 class="heading">Marketing Investments</h4>
                  <div
                    class="
                      d-flex
                      justify-content-between
                      align-items-center
                      py-1
                    "
                    v-for="(configure, index) in marketingInvestmentPercentages"
                    :key="index"
                  >
                    <span class="w-50 fw-bold">{{ configure.name }} </span>
                    <div class="w-75 d-flex align-items-center">
                      <span class="slider-value">{{ configure.value }}</span>
                      <Slider
                        class="w-100"
                        v-model:value="configure.value"
                        :value="configure.value"
                        :min="configure.min"
                        :max="configure.max"
                        :step="configure.step"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </el-col>
            <el-col :span="24">
              <div class="card mb-7">
                <div class="card-body">
                  <h4 class="heading">Marketing Investments Exit</h4>
                  <div
                    class="
                      d-flex
                      justify-content-between
                      align-items-center
                      py-1
                    "
                    v-for="(configure, index) in yearlyRevenueMultiple"
                    :key="index"
                  >
                    <span class="w-50 fw-bold">{{ configure.name }} </span>
                    <div class="w-75 d-flex align-items-center">
                      <span class="slider-value">{{ configure.value }}</span>
                      <Slider
                        class="w-100"
                        v-model:value="configure.value"
                        :value="configure.value"
                        :min="configure.min"
                        :max="configure.max"
                        :step="configure.step"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="modal-footer flex-end bg-light-grey">
          <button
            type="reset"
            class="btn me-3 btn-primary"
            data-bs-dismiss="modal"
            @click="configureForecast"
          >
            Save
          </button>
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref, watch } from "vue";
import HistoricRevenueV2 from "@/forecast/components/HistoricRevenueV2.vue";
import BaseForecastV2 from "@/forecast/components/BaseForecastV2.vue";
import MarketingBudgetV2 from "@/forecast/components/MarketingBudgetV2.vue";
import MarketingForecastV2 from "@/forecast/components/MarketingForecastV2.vue";
import MarketingInvestmentsV2 from "@/forecast/components/MarketingInvestmentsV2.vue";
import SubTopNavBarV2 from "@/common/components/SubTopNavBarV2.vue";
import Vue3Html2pdf from "vue3-html2pdf";
import ForecastSliderCard from "@/artists/components/ForecastSliderCard.vue";
import Slider from "@/artists/components/Slider.vue";
import { useStore } from "vuex";

export default {
  name: "ForecastDetailV2",
  components: {
    Slider,
    ForecastSliderCard,
    SubTopNavBarV2,
    HistoricRevenueV2,
    BaseForecastV2,
    MarketingBudgetV2,
    MarketingForecastV2,
    MarketingInvestmentsV2,
    Vue3Html2pdf,
  },
  setup() {
    const loading = ref(false);
    const store = useStore();
    const marketingBudgetChildRef = ref();
    const marketingForecastChildRef = ref();
    const marketingInvestmentChildRef = ref();
    const forecastSectionPdf = ref();
    const scenarios = ref(["positive", "medium", "negative"]);
    const forecastConfigurationValues = ref({});
    const baseForecastConfigurationPercentages = computed(
      () =>
        store.getters["ForecastModule/getBaseForecastConfigurationPercentages"]
    );
    const yearlyMarketingBudgetsPercentages = ref([
      {
        base_budget: 8,
        upside_budget: 20,
      },
      {
        base_budget: 10,
        upside_budget: 20,
      },
      {
        base_budget: 12,
        upside_budget: 24,
      },
      {
        base_budget: 14,
        upside_budget: 30,
      },
    ]);
    const marketingForecastPercentages = ref([
      {
        name: "Growth Rate of Base Budgets",
        value: 7,
        step: 1,
        min: 0,
        max: 50,
      },
      {
        name: "Growth Rate of Upside Budgets",
        value: 7,
        step: 1,
        min: 0,
        max: 50,
      },
    ]);
    const marketingInvestmentPercentages = ref([
      { name: "BlingRock Ownership", value: 49, min: 0, max: 75, step: 1 },
      {
        name: "Proposed Catalogue Valuation",
        value: 4,
        min: 0,
        max: 100,
        step: 1,
      },
    ]);
    const yearlyRevenueMultiple = ref([
      {
        name: "Multiple of yearly revenues",
        value: 16,
        min: 0,
        max: 32,
        step: 1,
      },
    ]);
    const activeTab = ref("forecast_historic_revenues");
    const tabMenuList = ref([
      {
        id: "forecast_historic_revenues",
        name: "Historic Revenues",
        icon: "/media/svg/revs.svg",
        alt: "forecast-historic-revenues",
      },
      {
        id: "base_forecast",
        name: "Base Forecast",
        icon: "/media/svg/revs.svg",
        alt: "base-forecast",
      },
      {
        id: "forecast_marketing_budgets",
        name: "Marketing Budgets",
        icon: "/media/svg/revs.svg",
        alt: "forecast-marketing-budgets",
      },
      {
        id: "forecast_marketing",
        name: "Marketing Forecasts",
        icon: "/media/svg/revs.svg",
        alt: "forecast-marketing",
      },
      {
        id: "forecast_investment",
        name: "Investment",
        icon: "/media/svg/revs.svg",
        alt: "forecast_investment",
      },
    ]);

    const generateReport = () => {
      loading.value = true;
      forecastSectionPdf.value.generatePdf();
    };

    const onProgress = (val) => {
      if (val === 100) {
        loading.value = false;
      }
    };

    const configureForecast = () => {
      marketingBudgetChildRef.value.configure();
      marketingForecastChildRef.value.configure();
      marketingInvestmentChildRef.value.configure();
      store.dispatch("ForecastModule/setBaseForecastConfigurationValues", {
        values: forecastConfigurationValues.value,
        status: true,
      });
    };
    watch(
      () => baseForecastConfigurationPercentages.value,
      (value) => {
        if (value) {
          forecastConfigurationValues.value = value.values;
        }
      },
      { immediate: true, deep: true }
    );

    return {
      loading,
      activeTab,
      tabMenuList,
      scenarios,
      forecastConfigurationValues,
      yearlyMarketingBudgetsPercentages,
      forecastSectionPdf,
      marketingInvestmentPercentages,
      marketingForecastPercentages,
      yearlyRevenueMultiple,
      marketingBudgetChildRef,
      marketingForecastChildRef,
      marketingInvestmentChildRef,
      configureForecast,
      generateReport,
      onProgress,
    };
  },
};
</script>
<style>
.forecast-sub-navBar {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 11;
  transition: 0.8s;
}

.border-right {
  border-right: 2px solid #dee4ee !important;
}

.pdf-top-margin {
  margin-top: 7rem;
}

.bg-light-grey {
  background-color: #f4f7f9;
}

.slider-value {
  margin-right: 1.5rem;
}
</style>
