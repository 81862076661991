<template>
  <div class="marketing-forecasts mt-2">
    <el-row :gutter="12" class="margin-bottom-12">
      <el-col :lg="24" :md="24" :sm="24" :xs="24">
        <div class="shadow-card card kpi-card-body">
          <el-row :gutter="12" class="ps-4 pe-4">
            <el-col
              :lg="8"
              :md="8"
              :sm="8"
              :xs="8"
              class="border-right theme-color"
            >
              <p
                class="kpi-card-title truncate mb-1"
                data-tag="p"
                title=""
                data-tooltip="true"
                :data-text="formatNumber(assumedGrowth, 1) + '%'"
                :style="[pdf ? 'font-size: 29px' : 'font-size: 35px']"
              >
                {{ formatNumber(assumedGrowth, 1) + "%" }}
              </p>
              <p
                class="kpi-card-text truncate mb-0"
                data-tag="p"
                title=""
                data-tooltip="true"
                :data-text="'Assumed YOY Growth Rate'"
              >
                Assumed YOY Growth Rate
              </p>
            </el-col>
            <el-col
              :lg="4"
              :md="4"
              :sm="4"
              :xs="4"
              :class="[pdf ? 'pt-2' : 'pt-4']"
              v-for="(revenue, i) in revenues"
              :key="i"
            >
              <KpiCardV2
                :title="formatNumber(revenue)"
                :text="'Revenues Fy' + (i + 1)"
                :card="false"
                :pdf="pdf"
              />
            </el-col>
          </el-row>
        </div>
      </el-col>
    </el-row>
    <div>
      <CardToolbar
        :description="''"
        :title="'Marketing Forecast'"
        :fontStyle="true"
        :padding="false"
        :shadow-class="true"
        :show-toolbar-buttons="false"
      >
        <template v-slot:body>
          <RevenueChart
            v-if="!loading"
            :chart-type="'bar'"
            :series="series"
            :colors="['#009EF7', '#33647f']"
            :currency-values="true"
            :categories="categories"
            :stroke-width="[0, 10]"
            :plot-options="{
              bar: {
                horizontal: false,
                columnWidth: '80%',
              },
            }"
            :stacked="true"
            :hit-events="true"
          />
        </template>
      </CardToolbar>
    </div>
  </div>
</template>
<script>
import KpiCardV2 from "@/common/components/KpiCardV2.vue";
import CardToolbar from "@/common/components/CardToolbar.vue";
import RevenueChart from "@/common/components/RevenueChart.vue";
import { useStore } from "vuex";
import { computed, ref, watch } from "vue";
import {
  checkIfValueGreaterThanZeroThenHumanize,
  chunkArrayIntoEqualSizes,
  sumArrayElements,
} from "@/utils/staticHelper";

export default {
  name: "MarketingForecastsV2",
  components: {
    KpiCardV2,
    CardToolbar,
    RevenueChart,
  },
  props: {
    pdf: {
      type: Boolean,
      default: false,
    },
    percentages: {
      type: Array,
      required: true,
    },
  },
  setup(props, context) {
    const store = useStore();
    const loading = ref(false);
    const assumedGrowth = ref(0);
    const series = ref([
      {
        name: "Base Market Growth",
        data: [],
      },
      {
        name: "Marketing Forecast",
        data: [],
      },
    ]);
    const categories = ref([
      "Q1",
      "Q2",
      "Q3",
      "Q4",
      "Q5",
      "Q6",
      "Q7",
      "Q8",
      "Q9",
      "Q10",
      "Q11",
      "Q12",
      "Q13",
      "Q14",
      "Q15",
      "Q16",
    ]);
    const marketingForecastPercentages = ref(props.percentages);
    const revenues = ref([]);
    const getBaseForecastMarketGrowth = computed(
      () => store.getters["ForecastModule/getBaseForecastMarketGrowth"]
    );
    const lastYearRevenue = computed(
      () => store.getters["ForecastModule/getForecastLastYearRevenue"]
    );
    const configure = () => {
      loading.value = true;
      calculateQuarterlyMarketingForecast(
        getBaseForecastMarketGrowth.value,
        marketingForecastPercentages.value
      );
    };
    const calculateQuarterlyMarketingForecast = (marketGrowths, configures) => {
      series.value[0].data = marketGrowths;
      series.value[1].data = [];
      revenues.value = [];
      let base_revenue = marketGrowths[0];
      for (let i = 0; i < 16; i++) {
        const predictedQuarter =
          (i === 0 ? 0 : base_revenue) +
          (configures[0].value / 100 + configures[1].value / 100) *
            base_revenue;
        series.value[1].data.push(predictedQuarter);
        base_revenue = predictedQuarter;
      }
      const sumSeries = series.value[0].data.map(function (num, idx) {
        return num + series.value[1].data[idx];
      });
      store.dispatch("ForecastModule/setMarketingForecasts", sumSeries);

      const dividedArray = chunkArrayIntoEqualSizes(sumSeries, 4);
      for (const data of dividedArray) {
        revenues.value.push(sumArrayElements(data));
      }
      calculateAssumedGrowth();
      loading.value = false;
    };
    const calculateAssumedGrowth = () => {
      assumedGrowth.value =
        (((revenues.value[3] - lastYearRevenue.value) / lastYearRevenue.value) *
          100) /
        4;
    };
    const formatNumber = (val, fraction = 0) => {
      return checkIfValueGreaterThanZeroThenHumanize(val, fraction);
    };

    // for calling child function from parent
    context.expose({ configure });

    watch(
      () => getBaseForecastMarketGrowth.value,
      (values) => {
        loading.value = true;
        if (values.length > 0) {
          calculateQuarterlyMarketingForecast(
            values,
            marketingForecastPercentages.value
          );
        }
      },
      { immediate: true, deep: true }
    );
    watch(
      () => lastYearRevenue.value,
      (value) => {
        if (value !== null) {
          calculateAssumedGrowth();
        }
      },
      { immediate: true, deep: true }
    );
    return {
      revenues,
      series,
      categories,
      loading,
      getBaseForecastMarketGrowth,
      marketingForecastPercentages,
      assumedGrowth,
      configure,
      formatNumber,
      checkIfValueGreaterThanZeroThenHumanize,
    };
  },
};
</script>
<style scoped>
.bg-light-grey {
  background-color: #f4f7f9;
}

.slider-value {
  margin-right: 1.5rem;
}
</style>
