<template>
  <div class="marketing-budgets mt-2">
    <el-row :gutter="12">
      <el-col :lg="12" :md="12" :sm="24" :xs="24">
        <el-row :gutter="12">
          <el-col
            class="mb-4"
            :lg="12"
            :md="12"
            :sm="24"
            :xs="24"
            v-for="(budget, index) in yearlyMarketingBudgets"
            :key="index"
          >
            <div class="shadow-card card">
              <div class="card-body text-center">
                <h2 class="heading-style">Year {{ budget.year }}</h2>
                <el-row :gutter="12" class="mt-6">
                  <el-col :lg="12" :md="12" :sm="12" :xs="12">
                    <p
                      :class="[
                        'truncate mb-0 theme-v2-color',
                        pdf ? 'pdf-title' : 'kpi-card-title',
                      ]"
                      data-tag="p"
                      title=""
                      data-tooltip="true"
                      :data-text="budget.base_budget"
                    >
                      {{ budget.base_budget }}
                    </p>
                    <p
                      :class="[
                        'truncate mb-0 theme-color',
                        pdf ? 'pdf-text' : 'kpi-card-text',
                      ]"
                      data-tag="p"
                      title=""
                      data-tooltip="true"
                      :data-text="'Base Budget'"
                    >
                      Base Budget
                    </p>
                  </el-col>
                  <el-col :lg="12" :md="12" :sm="12" :xs="12">
                    <p
                      :class="[
                        'truncate mb-0 theme-v2-color',
                        pdf ? 'pdf-title' : 'kpi-card-title',
                      ]"
                      data-tag="p"
                      title=""
                      data-tooltip="true"
                      :data-text="budget.upside_budget"
                    >
                      {{ budget.upside_budget }}
                    </p>
                    <p
                      :class="[
                        'truncate mb-0 theme-color',
                        pdf ? 'pdf-text' : 'kpi-card-text',
                      ]"
                      data-tag="p"
                      title=""
                      data-tooltip="true"
                      :data-text="'Upside Budget'"
                    >
                      Upside Budget
                    </p>
                  </el-col>
                  <el-col :lg="24" :md="24" :sm="24" :xs="24" class="mt-8">
                    <p
                      :class="[
                        'truncate mb-0 theme-v2-color',
                        pdf ? 'pdf-title' : 'kpi-card-title',
                      ]"
                      data-tag="p"
                      title=""
                      data-tooltip="true"
                      :data-text="budget.assumed_growth_rate"
                      :style="[pdf ? 'font-size: 20px' : 'font-size: 30px']"
                    >
                      {{ budget.assumed_growth_rate }}
                    </p>
                    <p
                      :class="[
                        'truncate mb-0 theme-color',
                        pdf ? 'pdf-text' : 'kpi-card-text',
                      ]"
                      data-tag="p"
                      title=""
                      data-tooltip="true"
                      :data-text="'Assumed YOY Growth Rate'"
                    >
                      Assumed YOY Growth Rate
                    </p>
                  </el-col>
                </el-row>
              </div>
            </div>
          </el-col>
        </el-row>
      </el-col>
      <el-col :lg="12" :md="12" :sm="24" :xs="24">
        <CardToolbar
          :show-toolbar-buttons="false"
          description=""
          :title="'Marketing Budgets over time'"
          :fontStyle="true"
          :padding="false"
          :shadow-class="true"
        >
          <template v-slot:body>
            <RevenueChart
              v-if="!loading"
              :chart-height="pdf ? 320 : 385"
              :chart-type="'bar'"
              :stacked="true"
              :series="series"
              :colors="['#009EF7', '#33647f']"
              :currency-values="true"
              :categories="marketingBudgetCategories"
              :stroke-width="[0, 10]"
              :plot-options="{
                bar: {
                  horizontal: false,
                  columnWidth: '80%',
                },
              }"
              :chart-class="false"
              :hit-events="true"
            />
          </template>
        </CardToolbar>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import { computed, ref, watch } from "vue";
import CardToolbar from "@/common/components/CardToolbar.vue";
import RevenueChart from "@/common/components/RevenueChart.vue";
import { useStore } from "vuex";
import { formatNumberIntoHumanizeForm } from "@/utils/staticHelper";

export default {
  name: "MarketingBudgetV2",
  components: { CardToolbar, RevenueChart },
  props: {
    pdf: {
      type: Boolean,
      default: false,
    },
    percentages: {
      type: Array,
      required: true,
    },
  },
  setup(props, context) {
    const store = useStore();
    const loading = ref(false);
    const marketingBudgetCategories = ref([
      "Q1",
      "Q2",
      "Q3",
      "Q4",
      "Q5",
      "Q6",
      "Q7",
      "Q8",
      "Q9",
      "Q10",
      "Q11",
      "Q12",
      "Q13",
      "Q14",
      "Q15",
      "Q16",
    ]);
    const series = ref([
      {
        name: "Base Budgets",
        data: [],
      },
      {
        name: "Upside Budgets",
        data: [],
      },
    ]);
    const yearlyMarketingBudgets = ref([]);
    const yearlyMarketingBudgetsPercentages = ref(props.percentages);
    const seriesPercentages = ref([40, 20, 30, 10]);
    const baseForecastRevenuesSum = computed(
      () => store.getters["ForecastModule/getBaseForecastRevenuesSum"]
    );
    const configure = () => {
      loading.value = true;
      calculateYearlyMarketingBudgets(
        baseForecastRevenuesSum.value,
        yearlyMarketingBudgetsPercentages.value
      );
    };
    const calculateYearlyMarketingBudgets = (revenues, percentages) => {
      yearlyMarketingBudgets.value = [];
      series.value[0].data = [];
      series.value[1].data = [];
      let maxMarketingBudgets = 0;
      revenues.map((e, index) => {
        const baseBudget = e * (percentages[index].base_budget / 100);
        const upsideBudget = e * (percentages[index].upside_budget / 100);
        const assumedGrowthRate = baseBudget + upsideBudget;
        yearlyMarketingBudgets.value.push({
          year: index + 1,
          base_budget: formatNumberIntoHumanizeForm(baseBudget, 0),
          upside_budget: formatNumberIntoHumanizeForm(upsideBudget, 0),
          assumed_growth_rate: formatNumberIntoHumanizeForm(
            assumedGrowthRate,
            0
          ),
        });
        maxMarketingBudgets += assumedGrowthRate;
        for (let i = 0; i < 4; i++) {
          series.value[0].data.push(
            (percentages[index].base_budget / 100) *
              e *
              (seriesPercentages.value[i] / 100)
          );
          series.value[1].data.push(
            (percentages[index].upside_budget / 100) *
              e *
              (seriesPercentages.value[i] / 100)
          );
        }
      });
      store.dispatch(
        "ForecastModule/setMaxMarketingBudgets",
        maxMarketingBudgets
      );
      series.value[1].data[0] = 0;
      series.value[1].data[1] = 0;
      series.value[1].data[2] =
        (percentages[0].upside_budget / 100) *
        revenues[0] *
        (seriesPercentages.value[0] / 100);
      series.value[1].data[3] =
        (percentages[0].upside_budget / 100) *
        revenues[0] *
        (seriesPercentages.value[1] / 100);
      loading.value = false;
    };

    // for calling child function from parent
    context.expose({ configure });

    watch(
      () => baseForecastRevenuesSum.value,
      (values) => {
        loading.value = true;
        if (values.length > 0) {
          calculateYearlyMarketingBudgets(
            values,
            yearlyMarketingBudgetsPercentages.value
          );
        }
      }
    );

    return {
      series,
      marketingBudgetCategories,
      yearlyMarketingBudgets,
      yearlyMarketingBudgetsPercentages,
      loading,
      configure,
    };
  },
};
</script>
<style scoped>
.bg-light-grey {
  background-color: #f4f7f9;
}

.slider-value {
  margin-right: 1.5rem;
}

.heading-style {
  font-size: 14px;
  font-weight: 500;
  color: #33647f;
}
</style>
