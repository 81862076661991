<template>
  <div class="historic-revenue mt-2" v-loading.fullscreen.lock="loading">
    <el-row :gutter="12">
      <el-col :lg="12" :md="12" :sm="24" :xs="24" class="margin-bottom-12">
        <div class="shadow-card card kpi-card-body">
          <el-row>
            <el-col :lg="8" :md="8" :xs="8" :sm="8" class="ps-6 pe-6">
              <KpiCardV2
                :title="
                  checkIsValueAvailableOrNot(
                    'object',
                    listenerGrowths,
                    '12Months',
                    '%'
                  )
                "
                :text="'12 Months'"
                :sub-text="'Listeners Growth'"
                :card="false"
                :pdf="pdf"
              />
            </el-col>
            <el-col :lg="8" :md="8" :xs="8" :sm="8" class="ps-6 pe-6">
              <KpiCardV2
                :title="
                  checkIsValueAvailableOrNot(
                    'object',
                    listenerGrowths,
                    '24Months',
                    '%'
                  )
                "
                :text="'24 Months'"
                :sub-text="'Listeners Growth'"
                :card="false"
                :pdf="pdf"
              />
            </el-col>
            <el-col :lg="8" :md="8" :xs="8" :sm="8" class="ps-6 pe-6">
              <KpiCardV2
                :title="
                  checkIsValueAvailableOrNot(
                    'object',
                    listenerGrowths,
                    '36Months',
                    '%'
                  )
                "
                :text="'36 Months'"
                :sub-text="'Listeners Growth'"
                :card="false"
                :pdf="pdf"
              />
            </el-col>
          </el-row>
        </div>
      </el-col>
      <el-col :lg="12" :md="12" :sm="24" :xs="24" class="margin-bottom-12">
        <div class="shadow-card card kpi-card-body">
          <el-row :gutter="12">
            <el-col :lg="8" :md="8" :xs="8" :sm="8" class="ps-6 pe-6">
              <KpiCardV2
                :title="
                  checkIsValueAvailableOrNot(
                    'object',
                    revenues,
                    'firstYearRevenue',
                    '',
                    0
                  )
                "
                :text="'Last 12 Months'"
                :sub-text="'Revenues'"
                :card="false"
                :pdf="pdf"
              />
            </el-col>
            <el-col :lg="8" :md="8" :xs="8" :sm="8" class="ps-6 pe-6">
              <KpiCardV2
                :title="
                  checkIsValueAvailableOrNot(
                    'object',
                    revenues,
                    'secondYearRevenue',
                    '',
                    0
                  )
                "
                :text="'2 Years Ago'"
                :sub-text="'Revenues'"
                :card="false"
                :pdf="pdf"
              />
            </el-col>
            <el-col :lg="8" :md="8" :xs="8" :sm="8" class="ps-6 pe-6">
              <KpiCardV2
                :title="
                  checkIsValueAvailableOrNot(
                    'object',
                    revenues,
                    'thirdYearRevenue',
                    '',
                    0
                  )
                "
                :text="'3 Years Ago'"
                :sub-text="'Revenues'"
                :card="false"
                :pdf="pdf"
              />
            </el-col>
          </el-row>
        </div>
      </el-col>
    </el-row>
    <CardToolbar
      :show-toolbar-buttons="false"
      description=""
      :title="'Estimation of historic revenues'"
      :fontStyle="true"
      :padding="false"
      :shadow-class="true"
      v-if="!loading"
    >
      <template v-slot:body>
        <RevenueChart
          :series="series"
          :colors="['#009EF7', '#009EF7', '#009EF7']"
          :currency-values="true"
          :categories="graphCategories"
          :stroke-width="[5, 5, 5]"
          :fill="{ opacity: [1, 0.6, 0.2] }"
        />
      </template>
    </CardToolbar>
  </div>
</template>
<script>
import { onMounted, ref } from "vue";
import CardToolbar from "@/common/components/CardToolbar.vue";
import RevenueChart from "@/common/components/RevenueChart.vue";
import KpiCardV2 from "@/common/components/KpiCardV2.vue";
import { useRoute } from "vue-router";
import { getArtistHistoricForecastV2FromMongo } from "@/api/mongoDB";
import { checkIsValueAvailableOrNot } from "@/utils/staticHelper";
import { useStore } from "vuex";

export default {
  name: "Historic Revenue V2",
  components: {
    CardToolbar,
    RevenueChart,
    KpiCardV2,
  },
  props: {
    pdf: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const loading = ref(false);
    const route = useRoute();
    const store = useStore();
    const graphCategories = ref([
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ]);
    const series = ref([]);
    const listenerGrowths = ref({});
    const revenues = ref({
      firstYearRevenue: 0,
      secondYearRevenue: 0,
      thirdYearRevenue: 0,
    });
    onMounted(async () => {
      if (route.params.spotifyID) {
        await getHistoricForecast();
      }
    });

    const getHistoricForecast = async () => {
      try {
        loading.value = true;
        series.value = [];
        let { data } = await getArtistHistoricForecastV2FromMongo(
          route.params.spotifyID
        );
        await getSeriesData(
          data.lastOneYearOfFanMetricEverySingleMonthData
            ? data.lastOneYearOfFanMetricEverySingleMonthData
            : [],
          "Last 12 Months"
        );
        await getSeriesData(
          data.lastSecondYearOfFanMetricEverySingleMonthData
            ? data.lastSecondYearOfFanMetricEverySingleMonthData
            : [],
          "2 Years Ago"
        );
        await getSeriesData(
          data.lastThirdYearOfFanMetricEverySingleMonthData
            ? data.lastThirdYearOfFanMetricEverySingleMonthData
            : [],
          "3 Years Ago"
        );
        listenerGrowths.value = data.listenerGrowths
          ? data.listenerGrowths
          : {};
        revenues.value.firstYearRevenue = data.firstYearRevenue
          ? data.firstYearRevenue
          : 0;
        revenues.value.secondYearRevenue = data.secondYearRevenue
          ? data.secondYearRevenue
          : 0;
        revenues.value.thirdYearRevenue = data.thirdYearRevenue
          ? data.thirdYearRevenue
          : 0;
        await store.dispatch(
          "ForecastModule/setForecastLastYearRevenue",
          data.firstYearRevenue ? data.firstYearRevenue : 0
        );
        data = {};
      } catch (e) {
        console.log(e);
      } finally {
        loading.value = false;
      }
    };
    const getSeriesData = (array, seriesName) => {
      let yearlyDataArray = [];
      let lastMonthData = 0;
      for (const data of array) {
        lastMonthData = data.value ? data.value : lastMonthData;
        yearlyDataArray.push(lastMonthData);
      }
      series.value.push({
        name: seriesName,
        type: "line",
        data: yearlyDataArray,
      });
      yearlyDataArray = [];
      lastMonthData = 0;
    };
    return {
      loading,
      graphCategories,
      series,
      listenerGrowths,
      revenues,
      checkIsValueAvailableOrNot,
    };
  },
};
</script>
<style lang="scss"></style>
