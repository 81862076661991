import { ref } from "vue";
import { getFanmetricSourcesBySpotifyID } from "@/api/mongoDB";
import * as moment from "moment";

export const Status = {
  IDLE: "IDLE",
  RUNNING: "RUNNING",
  SUCCESS: "SUCCESS",
  ERROR: "ERROR",
};

export default async function useSpotifyMonthlyListenersFromMongoV2(
  spotifyID,
  years,
  sortOrder
) {
  async function fetchSpotifyData() {
    status.value = Status.RUNNING;
    try {
      const { data } = await getFanmetricSourcesBySpotifyID(
        spotifyID,
        "listeners",
        "spotify",
        years,
        sortOrder
      );
      status.value = Status.SUCCESS;
      const xAxis = [];
      const yAxis = [];
      for (const listener of data) {
        yAxis.push(listener.value);
        xAxis.push(moment(listener.timestp).format("MMM, YY"));
      }
      return {
        monthlyListeners: yAxis,
        months: xAxis,
      };
    } catch (e) {
      status.value = Status.ERROR;
      throw new Error(e);
    }
  }
  const status = ref(Status.IDLE);
  const spotifyData = ref(await fetchSpotifyData());

  return { spotifyData, status };
}
